<script>
import render from './custom/viewRender'

const layouts = {
  colItem(h, element,value) {
    element.disabled= true;
    let labelWidth = element.labelWidth ? `${element.labelWidth}px` : null
    if(element.viewType === 'component'){
		// showInView
		if (element.compIcon == "simpleImage" && !element.showInView){

		}else{
			return (
            <el-form-item label={element.showLabel ? element.label : ''}
                    label-width={labelWidth}
                    prop={element.id}
            >
              <render key={element.id} conf={element} value={value} onInput={ event => {
                this.$set(element,'value',event);
              }}/>
            </el-form-item>
			)
		}


    }else if(element.viewType === 'html'){
      return (
        <el-form-item label={element.showLabel ? element.label : ''}
          label-width={labelWidth}
          prop={element.id}
        >
          <fancy-html text={value}/>
        </el-form-item>
      )
    }else if(element.viewType === 'text'){
		// var style = {
		// 	fontSize:element.fontSize+"px",
		// 	lineHeight:element.lineHeight+"px",
		// 	color:element.color,
		// 	textAlign:element.textAlign
		// }
    //
		// var labelStyle={
		// 	fontSize:element.labelFontSize+"px",
		// 	lineHeight:element.labelLineHeight+"px",
		// 	color:element.labelColor,
		// 	width:labelWidth,
		// 	display:element.showLabel ? 'block': 'none'
		// }
		// return (
		// 	<div style="display:flex">
		// 		<div style={labelStyle}>{element.label}</div>
		// 		<div style="flex:1">
		// 			<div style={style}>{value}</div>
		// 		</div>
		// 	</div>
		// 	// <el-form-item label={element.showLabel ? element.label : ''}
		// 	// 			  label-width={labelWidth}
		// 	// 			  prop={element.id}
		// 	// 			  style="font-size:50px"
		// 	// >
		// 	// 	<div style={style}>{value}</div>
		// 	// </el-form-item>
		// )

      if (element.compName === '单行文本') {
        return (

            <el-form-item label={element.showLabel ? element.label : ''}
                          label-width={labelWidth}
            >
              <render key={element.id} conf={element} value={value} onInput={ event => {
                this.$set(element,'value',event);
              }}/>
            </el-form-item>
        )
      }
      else if (element.compName === '日期') {
        if(typeof value === 'object'){
          value = value[0] + ' ' + '至' + ' ' + value[1];
        }
        return (
            <el-form-item label={element.showLabel ? element.label : ''}
                          label-width={labelWidth}
                          prop={element.id}
            >
              {value}
            </el-form-item>
        )
      }
      else {
        return (

            <el-form-item label={element.showLabel ? element.label : ''}
                          label-width={labelWidth}
            >
              <div>{value}</div>
            </el-form-item>
        )
      }

	}else{
      if(typeof value === 'object'){
        value = value[0] + ' ' + element['range-separator'] + ' ' + value[1];
      }
      return (
        <el-form-item label={element.showLabel ? element.label : ''}
          label-width={labelWidth} 
          prop={element.id}
        >
          {value}
        </el-form-item>
      )
    }
    
  }
}

export default {
  name:"formViewItem",
  components: {
    render
  },
  props: ['model','value'],
  data(){
    return {
      eleConfig:this.model
    }
  },
  render(h) {
    return layouts.colItem.call(this, h, this.eleConfig,this.value)
  }
}
</script>
<style>
/* .el-form-item__label{
  font-weight: 600;
} */
.el-form-item{
  margin-left:10px;
  margin-right:10px;
  margin-bottom: 5px;
}

.el-form-item .el-textarea.is-disabled .el-textarea__inner {
  background-color: #FFFFFF;
}

.el-form-item .el-input.is-disabled .el-input__inner {
  background-color: #FFFFFF;
  color: #1a1a1a;
}
</style>