<template>
	<div >
		<el-card class="box-card" style="padding-top:20px;">
			<el-tabs v-model="tabName">
				<el-tab-pane
					:key="item.name"
					v-for="item in tableTabs"
					:label="item.title"
					:name="item.name"
					class="tab-pane"
				>
					<div v-if="item.name ==='form'">
						<form-builder ref="formBuilder" v-model="formVal" :buildData="formCode" v-if="itemList.length>0"/>
						<div style="margin-bottom:15px;text-align:center">
							<el-button type="primary" class="button" @click="handlerSubForm">提交</el-button>
						</div>
					</div>
					<codemirror v-model="code" :options="codeMirror" v-show="item.name ==='config'||item.name ==='value'"/>
					<div v-if="item.name==='view'">
						<form-viewer ref="formViewer" v-model="formEditVal" :buildData="formEditCode"></form-viewer>
					</div>
					<div v-if="item.name==='edit'">
						<form-builder ref="formBuilder" v-model="formEditVal" :buildData="formEditCode" />
						<div style="margin-bottom:15px;text-align:center">
							<el-button type="primary" class="button" @click="editFormData">保存</el-button>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>

		</el-card>
	</div>
</template>

<script>
import formBuilder from '../../components/common/formDesigner/formBuilder'
import formViewer from '../../components/common/formDesigner/formViewer'
import {codemirror} from 'vue-codemirror';
// 核心样式
import 'codemirror/lib/codemirror.css';
// 引入主题后还需要在 options 中指定主题才会生效
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript'
const options = {
	tabSize: 2, // 缩进格式
	theme: 'dracula', // 主题，对应主题库 JS 需要提前引入
	lineNumbers: true, // 显示行号
	line: true,
	styleActiveLine: true, // 高亮选中行
	hintOptions: {
		completeSingle: true // 当匹配只有一项的时候是否自动补全
	}
}
export default {
	components:{
		formBuilder,
		codemirror,
		formViewer
	},
	data(){
		return{
			formCode:'',
			formVal:'',
			formEditVal:'',
			formEditCode:'',
			tabName:'form',
			tableTabs: [],
			codeMirror:options
		}
	},
	created() {

	},
	mounted(){
		this.$nextTick(() => {
			this.getDiyTemplate();
			if (!this.$route.query.menuId && !this.$route.query.type && !this.$route.query.enrollId) {
				this.tableTabs =  [{
					title: '编辑表单',
					name: 'edit',
				}, {
					title: '配置',
					name: 'config',
				}, {
					title: '数据',
					name: 'value',
				}];
				this.tabName = 'edit';
				this.getRoomContent();
			}
			else if (!this.$route.query.menuId && this.$route.query.type) {
				this.tableTabs =  [{
					title: '查看',
					name: 'view',
				}];
				this.tabName = 'view';
				this.getRoomContent();
			}
      else if (this.$route.query.menuId || this.$route.query.enrollId) {
        this.tableTabs =  [{
          title: '预览表单',
          name: 'form',
        }]
      }
			else {
				this.tableTabs =  [{
					title: '预览表单',
					name: 'form',
				}, {
					title: '配置',
					name: 'config',
				}, {
					title: '数据',
					name: 'value',
				}]
			}
		})
		this.id = this.$route.params.id;
		this.getDiyTemplate();
	},

	methods:{
		getDiyTemplate(){
			var that  = this;
      if (that.$route.query.menuId) {
        that.newApi.getRoomTemplateInfo({"id":that.$route.query.id}).then(res=>{
          that.formCode = JSON.stringify(res.data.json);
          that.formEditCode = JSON.stringify(res.data.json)
        })
      }
      if (that.$route.query.enrollId) {
        that.newApi.getDiyTemplate({"id":that.$route.query.id}).then(res=>{
          that.formCode = JSON.stringify(res.data.json);
          that.formEditCode = JSON.stringify(res.data.json)
        })
      }
		},

		// 获取内容
		getRoomContent() {
			this.newApi.getRoomContentInfo({id: this.$route.query.contentId}).then(res =>{
				this.formEditVal = JSON.stringify(res.data.content);
				this.formEditCode = JSON.stringify(res.data.data)
			})
		},

		editFormData() {
			this.$refs['formBuilder'][0].editDate();
		},

		handlerSubForm(){
			if (this.$route.query.enrollId) {
				this.$refs['formBuilder'][0].toDate();
			} else {
				this.$refs['formBuilder'][0].validate();
			}
			if(this.formVal!==''){
				this.addNewTab();
				this.addEditTab();
			}
		},
		addNewTab(){
			if(this.tableTabs.length<4){
				this.tableTabs.push({
					title: '查看表单',
					name: 'view'
				});
			}

		},
		addEditTab(){
			if(this.tableTabs.length<5){
				this.tableTabs.push({
					title: '表单数据回显',
					name: 'edit'
				});
			}

		}
	},
	computed:{
		itemList(){
			if(this.formCode!==''){
				const form = JSON.parse(this.formCode);
				return form.list;
			}else{
				return [];
			}
		},
		code:{
			get() {
				if(this.tabName ==='form'){
					return ''
				}else if(this.tabName === 'config'){
					return this.formCode
				}else{
					return this.formVal
				}
			},
			set(){}
		}
	}
}
</script>

<style scoped>
/* .box-card >>> .el-tabs__header{
  margin: 0px 0px 0px 0px;
} */

.box-card{
	width:60%;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	overflow: auto;
	display: block;
}
#app{
	position: relative;
}
</style>
