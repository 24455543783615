<template>
    <div>
        <el-row :gutter="formConf.gutter" class="form-builder">
            <el-form
                    :rules="rules"
                    :ref="formConf.formModel"
                    :size="formConf.size"
                    :model="form"
                    :label-position="formConf.labelPosition"
                    :disabled="formConf.disabled"
                    :validate-on-rule-change="false"
                    :label-width="formConf.labelWidth + 'px'"
            >
                <div v-for="(element,index) in itemList" :key="index">
                    <preview-row-item
                            v-if="element.compType === 'row'"
                            :key="'row-'+index"
                            :model="element"
                    >
                        <el-col v-for="(column) in element.columns" :key="column.index" :span="column.span">
                            <div v-for="(col) in column.list" :key="col">
                                <preview-item
                                        v-if="col.compType!== 'dynamicTable'"
                                        :key="col.id"
                                        :model="col"
                                        v-model="form[col.id]"
                                        @valChange="handlerValChange"
                                />
                                <fancy-dynamic-table
                                        v-else-if="col.compType === 'dynamicTable'"
                                        ref="dynamicTable"
                                        :key="'dynamic-'+index"
                                        :data="form[col.id]"
                                        :conf="col"
                                        @addRow="handlerAddRow"
                                        @deleteRow="handlerDeleteRow"
                                >
                                    <template v-slot:item="{rowScope,item}">
                                        <fancy-dynamic-table-item
                                                :model="item"
                                                :parent="col"
                                                :key="'tableIndex-'+rowScope.$index"
                                                :index="rowScope.$index"
                                                v-model="rowScope.row[item.id]"
                                                @valChange="handlerDynamicValChange"
                                        />
                                    </template>
                                </fancy-dynamic-table>
                            </div>
                        </el-col>
                    </preview-row-item>
                    <fancy-dynamic-table
                            v-else-if="element.compType === 'dynamicTable'"
                            :key="'dynamic-'+index"
                            :data="form[element.id]"
                            :ref="element.id"
                            :conf="element"
                            @addRow="handlerAddRow"
                            @deleteRow="handlerDeleteRow"
                    >
                        <template v-slot:item="{rowScope,item}">
                            <fancy-dynamic-table-item
                                    :model="item"
                                    :ref="item.id+rowScope.$index"
                                    :parent="element"
                                    :key="'tableIndex-'+rowScope.$index"
                                    :index="rowScope.$index"
                                    v-model="rowScope.row[item.id]"
                                    @valChange="handlerDynamicValChange"
                            />
                        </template>
                    </fancy-dynamic-table>

                    <fancy-edit-table
                            v-else-if="element.compType === 'table'"
                            :layoutArray="element.layoutArray"
                            :tdStyle="element.tdStyle"
                            :width="element.width"
                            :height="element.height"
                    >
                        <template v-slot="{td}">
                            <div v-for="(col, index) in td.columns" :key="index">
                                <preview-item
                                        v-if="col.compType!== 'dynamicTable'"
                                        :key="col.id"
                                        :model="col"
                                        v-model="form[col.id]"
                                        @valChange="handlerValChange"
                                />
                            </div>
                        </template>
                    </fancy-edit-table>
                    <!--item-->

                    <el-col class="drag-col-wrapper" :key="index" :span="element.span" v-else>
                        <preview-item
                                :model="element"
                                v-model="form[element.id]"
                                @valChange="handlerValChange"
                        />
                    </el-col>
                </div>

            </el-form>
        </el-row>
    </div>
</template>
<script>
    import previewItem from "./previewItem";
    import previewRowItem from "./previewRowItem";
    import fancyDynamicTable from "./dynamic/fancyDynamicTable";
    import fancyDynamicTableItem from "./dynamic/fancyDynamicTableItem";
    import {datas, addRow, deleteRow, fillDatas} from "./custom/formDraw";
    import fancyEditTable from "./table/fancyEditTable";

    export default {
        name: 'formBuilder',
        props: {
            value: {
                type: String,
                default: ''
            },
            buildData: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            previewItem,
            previewRowItem,
            fancyDynamicTable,
            fancyDynamicTableItem,
            fancyEditTable
        },
        data() {
            return {
                form: {},
                rules: {},
                itemList: [],
                isInitData: false,
                isBuildFormData: false,
                currentIndex: -1
            }
        },
        mounted() {
            this.$nextTick(() => {
                //构建表单
                if (this.buildData !== '' && !this.isBuildFormData) {
                    const buildData = JSON.parse(this.buildData);
                    this.itemList = buildData.list;
                    this.isBuildFormData = true;
                }

                if (!this.isInitData) {
                    //初始化form函数
                    this.handlerInitDatas();
                }
                if (this.value !== '') {
                    const jsonValue = JSON.parse(this.value);
                    this.handlerFillDatas(jsonValue);
                }
            })
        },
        methods: {
            handlerValChange(key, origin) {
                this.$set(this.form, key, origin);
            },
            handlerDynamicValChange(parentId, index, key, origin) {
                this.$set(this.form[parentId][index], key, origin);
                this.currentIndex = index;
            },
            validate() {
                this.$refs[this.formConf.formModel].validate((valid) => {
                    if (valid) {
                        var params = {};
                        this.$message.success('success');
                        this.$emit('input', JSON.stringify(this.form, null, 4));
                        params.menuId = this.$route.query.menuId;
                        params.roomId = this.$route.query.roomId;
                        params.content = JSON.stringify(this.form);
                        params.data = this.buildData;
                        this.newApi.addRoomTemplateContent(params).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data)
                                this.$router.go(-1)
                            }
                        })
                    }
                });
            },

            toDate() {
                this.$refs[this.formConf.formModel].validate((valid) => {
                    if (valid) {
                        var params = {};
                        this.$emit('input', JSON.stringify(this.form, null, 4));
                        params.enrollId = this.$route.query.enrollId;
                        params.status = this.$route.query.status;
                        params.datas = JSON.stringify(this.form);
                        this.newApi.toEnroll(params).then(res => {
                          if (res.isSuccess === 1) {
                            if(!res.data.needPay){
                              this.$message.success("报名成功");
                            }
                            this.$router.go(-1);
                          }
                        })
                    }
                });
            },

            // 返回表单内容
            getContent() {
                var content = '';
                this.$refs[this.formConf.formModel].validate((valid) => {
                    if (valid) {
                        this.$emit('input', JSON.stringify(this.form, null, 4));
                        content = JSON.stringify(this.form);
                    } else {
                        this.$emit('input', '');
                    }
                });
                return content
            },

            editDate() {
                this.$refs[this.formConf.formModel].validate((valid) => {
                    if (valid) {
                        var params = {};
                        this.$message.success('success');
                        this.$emit('input', JSON.stringify(this.form, null, 4));
                        params.id = this.$route.query.contentId;
                        params.content = JSON.stringify(this.form);
                        params.data = this.buildData;
                        this.newApi.editTemplateContent(params).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data)
                                this.$router.go(-1)
                            }
                        })
                    }
                });
            },
            handlerAddRow: addRow,
            handlerDeleteRow: deleteRow,
            handlerInitDatas: datas,
            handlerFillDatas: fillDatas
        },
        computed: {
            formConf() {
                if (this.buildData !== '') {
                    const buildData = JSON.parse(this.buildData);
                    buildData.config.disabled = this.disabled;
                    return buildData.config;
                } else {
                    return {};
                }
            }
        },
        watch: {
            value() {
                //构建表单
                if (this.buildData !== '' && !this.isBuildFormData) {
                    const buildData = JSON.parse(this.buildData);
                    this.itemList = buildData.list;
                    this.isBuildFormData = true;
                }

                if (!this.isInitData) {
                    //初始化form函数
                    this.handlerInitDatas();
                }
                if (this.value !== '') {
                    const jsonValue = JSON.parse(this.value)
                    this.handlerFillDatas(jsonValue);
                }
            }
        }
    }
</script>
<style scoped>
    .preview-board {
        border: 1px dashed #ccc
    }

    .form-builder {
        padding: 20px;
        border: 1px solid #dcdfe6;
    }

    .el-form-item {
        margin-left: 10px;
        margin-right: 10px;

    }

    .form-builder >>> .el-radio.is-bordered + .el-radio.is-bordered {
        margin-left: 0px;
    }

    .form-builder >>> .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }

    .form-builder >>> .el-form-item {
        margin-bottom: 15px;
    }
</style>
